















































































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
// eslint-disable-next-line import/order
import cellRender from './agGrid/CellEditRender.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import {
  getComponent, getConfigEnv, getView, sleep,
} from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';
import FuzzySearch from 'fuzzy-search';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    FormDetailPopup: () => getView('FormDetailPopup'),
    AgGridVue,
    cellRender,

  },
})
export default class ActionBuiler extends Vue {
   context: any = null;

   searchQuery = '';

     columnDefs: any = [

     ];

    formCategories: any = []

    formArtificialLiftTypes: any = []

  deleteFormPopup = false

   formID = '';

   async confirmDeleteForm() {
     this.deleteFormPopup = false;
     await metaDataModule.deleteActionBuilder({ id: this.formID });
     await this.fetchActions();
   }

   deleteAction(id) {
     this.formID = id;
     this.deleteFormPopup = true;
   }

     showFormFilter = false;

     showFormDetail = false;

     formFilter: any = {
       categoriesSelected: [],
       titlesSelected: [],
       lastEditorSelected: [],
       lastEditStartDate: '',
       lastEditEndDate: '',
     }

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       filter: true,
       editable: false,
       autoHeight: true,
       cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
       resizable: true,
     };

       form: any = {
         id: null,
         title: '',
         category: '',
         artificialLiftTypes: [],
         description: '',
         updatedDate: '',
         updatedBy: '',
         lastThirtyDaysCount: 0,
         lifeTimeCount: 0,
         showLatestResponseOnWell: false,
         dateOfDisplay: { id: 0, title: 'Created date' },
         fields: [

         ],
       }

     defaultForm: any = {
       id: null,
       title: '',
       category: '',
       artificialLiftTypes: [],
       description: '',
       updatedDate: '',
       updatedBy: '',
       lastThirtyDaysCount: 0,
       lifeTimeCount: 0,
       dateOfDisplay: { id: 0, title: 'Created date' },
       showLatestResponseOnWell: false,
       fields: [

       ],
     }

    rowData:any = []

    cellClick(e) {
      // console.log(e);
    }

      getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  dataLoading = false;

  beforeMount() {
    this.context = { componentParent: this };
    // @ts-ignore
    window.gridApiVue = this;
  }

  filterCategories: any = [];

  filterFormTitles: any = [];

  filterFormEditors: any = [];

  filterFormlastEditEndDate: any = '';

  filterFormLastEditStartDate: any = ''

  resetFiltering(data) {
    console.log(data);
    this.showFormFilter = false;
    this.filterCategories = [];
    this.filterFormTitles = [];
    this.filterFormEditors = [];
    this.filterFormlastEditEndDate = '';
    this.filterFormLastEditStartDate = '';
    this.formFilter = data;
    setTimeout(() => {
      this.showFormFilter = true;
    }, 10);
  }

  applyFiltering(filtering) {
    console.log(filtering);
    this.filterCategories = [];
    this.filterFormTitles = [];
    this.filterFormEditors = [];
    this.filterFormlastEditEndDate = '';
    this.filterFormLastEditStartDate = '';

    if (filtering && filtering.categoriesSelected && filtering.categoriesSelected.length) {
      this.filterCategories = filtering.categoriesSelected.map((a) => a.text);
    }

    if (filtering && filtering.titlesSelected && filtering.titlesSelected.length) {
      this.filterFormTitles = filtering.titlesSelected.map((a) => a.text);
    }

    if (filtering && filtering.lastEditorSelected && filtering.lastEditorSelected.length) {
      this.filterFormEditors = filtering.lastEditorSelected.map((a) => a.text);
    }

    if (filtering && filtering.lastEditEndDate && filtering.lastEditEndDate.length) {
      this.filterFormlastEditEndDate = filtering.lastEditEndDate;
    }

    if (filtering && filtering.lastEditStartDate && filtering.lastEditStartDate.length) {
      this.filterFormLastEditStartDate = filtering.lastEditStartDate;
    }
    this.showFormFilter = false;
  }

  async created() {
    this.dataLoading = true;
    await accountModule.getReassignmentList({ useExisting: true });
    await this.fetchActions();
    await this.fetchDatalists();
    await this.dropdownOptions();
  }

  async fetchDatalists() {
    await metaDataModule.getDatalist();
  }

  get allFormsTitles() {
    return this.rowData.filter((item) => item.title && item.id !== this.form.id).map((form) => form.title.toLowerCase());
  }

  async prepareEditAction(id) {
    try {
      this.dataLoading = true;
      // const url: any = action.BuildDetailsUrl;
      const formPayload: any = await metaDataModule.getActionsBuilderRecords({ actionBuilderID: id });

      const selectedForm = this.rowData.find((form) => form.id === id);
      this.form = selectedForm;
      if (formPayload && formPayload.actionPayload) {
        this.form.fields = formPayload.actionPayload;
        if (!this.form.dateOfDisplay) {
          this.form.dateOfDisplay = { id: '0', title: 'Created date' };
        }
      }

      console.log(this.form);
      this.showFormDetail = true;

      this.dataLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  async editAction(id) {
    await this.prepareEditAction(id);
  }

  closeFormPopup() {
    this.showFormDetail = false;
    this.form = this.defaultForm;
  }

  async saveFormPopup() {
    this.showFormDetail = false;
    this.form = this.defaultForm;
    await this.fetchActions();
  }

  async dropdownOptions() {
    const requiredDataList = metaDataModule.requiredDatalist;
    const formCategoriesItem = requiredDataList.find((list) => list.title === 'Form Categories');
    const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
    if (formCategoriesItem) {
      const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
      if (formCategoriesPayload && formCategoriesPayload.length) {
        this.formCategories = formCategoriesPayload[0].ListItems.filter((field) => field.Active).map((item) => item.Name);
      }
    }

    if (formArtificialLiftItem) {
      const formArtificialLiftPayload = await metaDataModule.getDatalist(formArtificialLiftItem.id);
      if (formArtificialLiftPayload && formArtificialLiftPayload.length) {
        console.log(formArtificialLiftPayload);
        this.formArtificialLiftTypes = formArtificialLiftPayload[0].ListItems.filter((field) => field.Active).map((item) => item.Name);
        console.log(this.formArtificialLiftTypes);
      }
    }
  }

  async fetchActions() {
    this.dataLoading = true;
    const forms = await metaDataModule.getActionsBuilder();
    this.rowData = forms;
    console.log(forms);
    this.dataLoading = false;
  }

  async onGridReady(params) {
    this.gridApi = params.api;
    // this.rowData = [];
    this.columnDefs = [];
    this.gridColumnApi = params.columnApi;
    const columns = [];
    this.gridApi.hideOverlay();

    this.columnDefs = [
      {
        field: 'title',
        cellClass: 'noborder',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        sortable: true,
        filter: true,
        width: 390,
        colId: 'title',

        headerName: 'Form Name',
      },
      {
        field: 'category',
        editable: false,
        width: 180,
        colId: 'category',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Category',
      },
      {
        field: 'updatedBy',
        editable: false,
        width: 180,
        colId: 'updatedBy',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Latest Editor',
      },
      {
        field: 'updatedAt',
        editable: false,
        width: 180,
        colId: 'updatedAt',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Last Edit Date',
      },
      {
        field: 'lastThirtyDaysCount',
        editable: false,
        width: 280,
        colId: 'lastThirtyDaysCount',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: '30 Days Response Count',
      },
      {
        field: 'lifeTimeCount',
        editable: false,
        width: 300,
        colId: 'lifeTimeCount',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Lifetime Response Count',
      },
      {
        field: 'Actions',
        colId: 'actions',
        sortable: true,
        pinned: 'right',
        cellClass: 'noborder',
        suppressToolPanel: true,
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        suppressRowTransform: true,

        editable: false,

        initialWidth: 120,
        cellRendererFramework: 'cellRender',
        cellRendererParams: {
          clicked(field) {
            alert(`${field} was clicked`);
          },
        },
      },
    ];
  }

  get filteredFormData() {
    const searcher = new FuzzySearch(this.rowData, ['title', 'category'], {
	    caseSensitive: false,
	  });

    let result: any = searcher.search(this.searchQuery);

    if (this.filterCategories && this.filterCategories.length) {
      result = result.filter((r) => this.filterCategories.includes(r.category));
    }

    if (this.filterFormTitles && this.filterFormTitles.length) {
      result = result.filter((r) => this.filterFormTitles.includes(r.title));
    }

    if (this.filterFormEditors && this.filterFormEditors.length) {
      result = result.filter((r) => this.filterFormEditors.includes(r.updatedBy));
    }

    if (this.filterFormlastEditEndDate && this.filterFormlastEditEndDate.length) {
      console.log(new Date(this.filterFormlastEditEndDate).getTime());
      result = result.filter((r) => new Date(r.updatedAt).getTime() <= new Date(this.filterFormlastEditEndDate).getTime());
    }

    if (this.filterFormLastEditStartDate && this.filterFormLastEditStartDate.length) {
      console.log(new Date(this.filterFormLastEditStartDate).getTime());
      result = result.filter((r) => new Date(r.updatedAt).getTime() >= new Date(this.filterFormLastEditStartDate).getTime());
    }
	  return result;
  }
}

